import {
  GLOBAL_ORG_ID,
  NoAttribution,
  OrgId,
  isValidRedirectUrl,
} from "@hex/common";
import React, { useCallback } from "react";
import { Optional, Record as RRecord, Static } from "runtypes";

import { useListenToBroadcastChannel } from "../hooks/useListenToBroadcastChannnel";
import { getUrlOrg } from "../orgs";

import { Routes, useQueryParams } from "./routes";

export const LoginEvent = RRecord({
  orgId: Optional(OrgId),
});
export type LoginEvent = Static<typeof LoginEvent>;

export const LoginListener: React.ComponentType = React.memo(
  function LoginListener() {
    const queryParams = useQueryParams();
    const urlOrgId = getUrlOrg();

    // If no redirect is provided, redirect to login page.
    // Login pages should automatically redirect to home if they were successfully logged in
    // so only in the case that login actually somehow failed will the user re-see the login page again
    const redirectTo =
      queryParams.get("redirectTo") ??
      Routes.LOGIN.getUrl({
        attribution: NoAttribution,
        orgId: urlOrgId,
      });

    const onLogin = useCallback(
      async (ev) => {
        if (
          isValidRedirectUrl(redirectTo, window.location.hostname) &&
          ((LoginEvent.guard(ev) &&
            // If this login event didn't specify an org or we match orgs, handle it
            (ev.orgId == null || ev.orgId === (urlOrgId ?? GLOBAL_ORG_ID))) ||
            // Or if this is an unknown event time, try redirecting anyways
            !LoginEvent.guard(ev))
        ) {
          window.location.href = redirectTo;
        }
      },
      [redirectTo, urlOrgId],
    );

    useListenToBroadcastChannel("login", onLogin);

    return null;
  },
);
