import { gql } from "@apollo/client";
import { DOCS_LINKS, GLOBAL_ORG_ID, isValidRedirectUrl } from "@hex/common";
import { rgba } from "polished";
import React, { useEffect } from "react";
import styled from "styled-components";

import { NavigationBar } from "../components/app/NavigationBar";
import { EmbeddedRedirect } from "../components/login/EmbeddedRedirect";
import { LoginCard } from "../components/login/LoginCard";
import { LoginOption } from "../components/login/LoginOption";
import { LoginPage } from "../components/login/LoginPage";
import { OrgNameDisplay } from "../components/login/OrgNameDisplay";
import { useAttribution } from "../hooks/useAttribution";
import {
  StorageAccessState,
  useHasStorageAccess,
} from "../hooks/useHasStorageAccess";
import { LAST_ORG_STORAGE_KEY, getUrlOrg } from "../orgs";
import { useOrgsClientQuery } from "../orgs.generated";
import { safeLocalStorage } from "../util/browserStorage.js";
import { isSelfServeEnabled } from "../util/data";
import { markEmbedAsLoaded } from "../util/embedLoaded.js";

import { LoginListener } from "./LoginListener";
import { useOrgDetailsQuery } from "./LoginRoute.generated";
import { isEmbeddedUrl } from "./routeHooks.js";
import { QueryParams, Routes, useQueryParams } from "./routes";

export const Link = styled.a`
  color: ${({ theme }) => theme.marketingColors.ROSE_QUARTZ};
  text-align: center;
  text-decoration: none;

  border-bottom: 1px solid ${({ theme }) => theme.marketingColors.ROSE_QUARTZ};

  &:hover {
    color: inherit;
    text-decoration: none;

    background-color: ${({ theme }) =>
      rgba(theme.marketingColors.ROSE_QUARTZ, 0.3)};
  }
`;

const Workspaces = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0;
`;

const Divider = styled.span`
  padding: 0 10px;

  color: ${({ theme }) => theme.marketingColors.ROSE_QUARTZ};
`;

export const TosBlurb = styled.div`
  margin-top: 30px;

  color: ${({ theme }) => theme.marketingColors.ROSE_QUARTZ};
  font-size: ${({ theme }) => theme.fontSize.SMALL};

  line-height: 18px;
  text-align: center;
`;

const AlertContainer = styled.div`
  width: 100%;
  margin-bottom: 20px;
  padding: 16px 20px;

  color: ${({ theme }) => theme.marketingColors.OPAL};

  text-align: center;

  border: 1px solid ${({ theme }) => rgba(theme.marketingColors.OPAL, 0.2)};
`;

export const AlertHeader = styled.div`
  font-weight: ${({ theme }) => theme.fontWeight.MEDIUM};
  font-size: ${({ theme }) => theme.fontSize.LARGE};
`;

export const AlertBody = styled.div`
  margin-top: 7px;

  color: ${({ theme }) => theme.marketingColors.ROSE_QUARTZ};
  line-height: 20px;
`;

export const ErrorContainer = styled(AlertContainer)`
  color: ${({ theme }) => theme.marketingColors.OPAL};

  background-color: ${({ theme }) =>
    rgba(theme.marketingColors.ROSE_QUARTZ, 0.15)};
  border-color: ${({ theme }) => theme.marketingColors.ROSE_QUARTZ};
`;

const RequestAccessContainer = styled.div`
  padding: 25px;

  background-color: ${({ theme }) => theme.backgroundColor.MUTED};
  border-radius: ${({ theme }) => theme.borderRadius};
  box-shadow: ${({ theme }) => theme.boxShadow.CARD};
`;

const RequestAccessText = styled.div`
  margin: 17px 0;

  text-align: center;

  opacity: 0.5;
`;

const RequestAccessButtonContainer = styled.div`
  margin-bottom: 10px;
`;

gql`
  query OrgDetails($orgId: OrgId!) {
    publicOrgDetails(orgId: $orgId) {
      displayName
      ssoEnabled
      ssoEnforced
      googleAuth
      azureAuth
      allowsIframing
    }
  }
`;

// TODO: add validators
export const LoginRoute: React.ComponentType = React.memo(
  function LoginRoute() {
    const { data } = useOrgsClientQuery();

    const urlOrgId = getUrlOrg();

    const attribution = useAttribution();
    const queryParams = useQueryParams();
    const embedded = isEmbeddedUrl(queryParams);
    const failed = queryParams.get("failed") === "true";
    const invited = queryParams.get("invited") === "true";
    const newSignIn = queryParams.get("newSignIn") === "true";

    const { requestStorageAccess, storageAccessState } = useHasStorageAccess();

    useEffect(() => {
      if (embedded) {
        markEmbedAsLoaded();
      }
    }, [embedded]);

    const { data: publicOrgData } = useOrgDetailsQuery({
      variables: { orgId: urlOrgId ?? GLOBAL_ORG_ID },
    });

    const isAboutToRedirect =
      !urlOrgId &&
      data?.orgsClient.loaded &&
      data.orgsClient.orgs.length > 0 &&
      !newSignIn;

    useEffect(() => {
      if (isAboutToRedirect && data?.orgsClient.orgs != null) {
        const previousOrg = safeLocalStorage.getItem(LAST_ORG_STORAGE_KEY);
        if (
          previousOrg != null &&
          data.orgsClient.orgs.some((org) => org.id === previousOrg)
        ) {
          // If you had a previous org selected, use that one if its still valid
          window.location.href = `/${previousOrg}`;
        } else if (data.orgsClient.orgs[0] != null) {
          // If you're logged into at least one org and are at the global /login page, redirect to the first org
          window.location.href = `/${data.orgsClient.orgs[0].id}`;
        }
      }
    }, [isAboutToRedirect, data?.orgsClient.orgs]);

    const authQueryParams: QueryParams = {};
    const redirectTo = queryParams.get("redirectTo");
    if (
      redirectTo &&
      isValidRedirectUrl(redirectTo, window.location.hostname)
    ) {
      authQueryParams["redirectTo"] = redirectTo;
    }

    const ssoEnforced = publicOrgData?.publicOrgDetails?.ssoEnforced ?? false;

    const errorHeader = ssoEnforced
      ? "We couldn't find that account, you may need to ask an Admin to grant you access"
      : "We couldn't find that account.";
    const errorBody = (
      <>
        Head over to{" "}
        <Link href={Routes.SIGNUP.getUrl({ attribution })}>sign up</Link> to
        create an account.
      </>
    );

    const useOrgHeader = urlOrgId != null && urlOrgId !== GLOBAL_ORG_ID;

    if (urlOrgId == null && !data?.orgsClient.loaded) {
      return null;
    } else if (isAboutToRedirect) {
      return null;
    }

    return (
      <>
        <LoginListener />
        {!embedded && <NavigationBar isInApp={false} />}
        {embedded &&
          urlOrgId != null &&
          storageAccessState === StorageAccessState.HAS_ACCESS && (
            <EmbeddedRedirect
              authQueryParams={authQueryParams}
              orgId={urlOrgId}
            />
          )}
        <LoginPage
          embedded={embedded}
          orgName={
            useOrgHeader && urlOrgId ? (
              <OrgNameDisplay orgId={urlOrgId} />
            ) : undefined
          }
          superText={useOrgHeader ? "Logging in" : undefined}
        >
          {failed &&
            (invited ? (
              <ErrorContainer>
                <AlertHeader>Oops!</AlertHeader>
                <AlertBody>
                  You&apos;re already part of that workspace. Log in now.
                </AlertBody>
              </ErrorContainer>
            ) : (
              <ErrorContainer>
                <AlertHeader>{errorHeader}</AlertHeader>
                {isSelfServeEnabled && !ssoEnforced && (
                  <AlertBody>{errorBody}</AlertBody>
                )}
              </ErrorContainer>
            ))}
          {storageAccessState !== StorageAccessState.NO_ACCESS ? (
            <LoginCard
              authQueryParams={authQueryParams}
              embedded={embedded}
              orgId={urlOrgId}
            />
          ) : (
            <>
              <RequestAccessContainer>
                <RequestAccessText>
                  To view Hex embedded content, your browser requires you to
                  allow Hex to store cookies.
                </RequestAccessText>
                <RequestAccessButtonContainer>
                  <LoginOption onClick={requestStorageAccess}>
                    Continue
                  </LoginOption>
                </RequestAccessButtonContainer>
              </RequestAccessContainer>
            </>
          )}

          {!embedded && (isSelfServeEnabled || urlOrgId) && (
            <Workspaces>
              {isSelfServeEnabled && (
                <>
                  <Link href={Routes.SIGNUP.getUrl({ attribution })}>
                    Sign up for Hex
                  </Link>
                </>
              )}
              {isSelfServeEnabled && urlOrgId && <Divider>|</Divider>}
              {urlOrgId && (
                <Link
                  href={Routes.LOGIN.getUrl({
                    attribution,
                    params: {
                      newSignIn: "true",
                    },
                  })}
                >
                  Log in to another workspace
                </Link>
              )}
            </Workspaces>
          )}
          <TosBlurb>
            By logging in, you agree to our{" "}
            <Link href={DOCS_LINKS.Terms} target="_blank">
              Terms and Conditions
            </Link>{" "}
            and{" "}
            <Link href={DOCS_LINKS.Privacy} target="_blank">
              Privacy Policy.
            </Link>
          </TosBlurb>
        </LoginPage>
      </>
    );
  },
);
