import { CellId } from "@hex/common";
import { editor as Editor } from "monaco-editor";

const editorsByCellId: Record<CellId, Editor.IStandaloneCodeEditor | null> = {};

/**
 * This returns an editor instance given a cellId.
 * The entry will only exist if the editor has been registered by the component that mounted it.
 */
export const getEditorForCellId = (
  cellId: CellId,
): Editor.IStandaloneCodeEditor | null => {
  return editorsByCellId[cellId];
};

export const registerEditorForCellId = (
  cellId: CellId,
  editor: Editor.IStandaloneCodeEditor,
): void => {
  editorsByCellId[cellId] = editor;
};

export const removeEditorForCellId = (cellId: CellId): void => {
  editorsByCellId[cellId] = null;
};
